import { useState } from "react";
import Layout from "layout/Layout";
import Header from "layout/Header";
import Content from "layout/Content";
import Footer from "layout/Footer";
import {
  Splash,
  Simple,
  SpecialPackages,
  Testimonials,
  AboutUs,
  Inclusive,
  FitnessFamily,
  ContactForm
} from "features";
import './App.css';

function App() {

  const [formIsVisible, setFormIsVisible] = useState(false)

  const showForm = () => setFormIsVisible(true)

  return (
    <Layout>
      <ContactForm isVisible={formIsVisible} setIsVisible={setFormIsVisible} />
      <Header showForm={showForm} />
      <Content>
        <Splash showForm={showForm} />
        <Simple showForm={showForm} />
        <SpecialPackages showForm={showForm} />
        <AboutUs />
        <Inclusive />
        <FitnessFamily showForm={showForm} />
        <Testimonials />
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
