import styled from "styled-components";


const headerShared = `
  text-transform: uppercase;
  color: var(--text-color);
  & span {
    background: var(--primary-color);
    padding: 0 8px;
    // transform: rotate(1deg);
    display: inline-block;
  }
`
export const HOne = styled.h1`
  width: 100%;
  font-size: clamp(44px, 5.7vw, 110px);
  letter-spacing: 2px;
  position: relative;
  font-weight: 600;
  font-weight: 800;
  ${headerShared}
`;

export const HTwo = styled.h2`
  width: 100%;
  font-size: clamp(28px, 2.7vw, 55px);
  font-weight: 800;
  ${headerShared}
`;

export const HThree = styled.h3`
  width: 100%;
  font-size: clamp(18px, 1.5vw, 28px);
  font-weight: 500;
  ${headerShared}
`;

export const Paragraph = styled.p`
  white-space: pre-line;
  font-size: clamp(16px, 1.3vw, 18px);
  color: var(--text-color);
  line-height: 1.9;
  margin-top: 0;
`;

export const Link =  styled.a`
  color: var(--text-color);
  text-decoration: ${props => props.$active ? "underline" : "none"};
  &:hover {
    color: var(--primary-color);
  }
`;

const Typography = { HOne, HTwo, HThree, Paragraph, Link };

export default Typography;
