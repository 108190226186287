import _ from "lodash";
import en from "localisation/pain-and-gain_English.json"
import nl from "localisation/pain-and-gain_Dutch.json"

export default _.mapValues({
  en,
  nl,
  // fr,
  // de,
  // es,
}, (lang) => {
  return ({
    translation: _.reduce(lang, (acc, val) => {
      acc[val.term] = val.definition;
      return acc;
    }, {}) 
  })
})