import styled from "styled-components";
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { Button, SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import testimonials1 from "assets/images/testimonials-1.png";
import testimonials2 from "assets/images/testimonials-2.png";
import testimonials3 from "assets/images/testimonials-3.png";

const reviewers = ["alex", "sarah", "david", "sanne"]
const profiles = [testimonials1, testimonials2]

const Customize = () => {

  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer id="testimonials">
      <ContentContainer>
        <StyledRow>
          <TopCol lg={24}>
            <div>
              <HTwo><Trans i18nKey="testimonials.header" components={[<span />]} /></HTwo>
              <Paragraph>{t("testimonials.subHeader")}</Paragraph>
            </div>
            <div>
              <Button type="primary" size="large">{t("testimonials.cta")}</Button>
            </div>
          </TopCol>
        </StyledRow>
        <StyledRow gutter={24}>
          {reviewers.map((key, i) => (
            <StyledCol md={24} lg={24} key={key}>
              <Panel>
                {/* <div>
                  <StyledImg src={profiles[i]} alt="Alex Martin" />
                </div> */}
                <div>
                  <Strong>@{t(`testimonials.${key}.uname`)}</Strong>
                  <Paragraph>{t(`testimonials.${key}.review`)}</Paragraph>
                  <Paragraph>{t(`testimonials.${key}.detail`)}</Paragraph>
                </div>
              </Panel>
            </StyledCol>
          ))}
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}
const Panel = styled.div`
  border: 1px solid var(--primary-color);
  padding: 20px;
  border-radius: 3px;
  background: rgba(224, 31, 31, 0.1);
  display: flex;
  margin-bottom: 20px;
  & ${Paragraph} {
    font-size: 16px;
    line-height: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    &:last-child {
      text-decoration: underline;
    }
  }
`;
const StyledImg = styled.img`
  height: 60px;
  margin-right: 10px;
`;
const Strong = styled(Paragraph)`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: white;
  line-height: 1;
`;
const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  & ${Paragraph} {
    margin: 0;
  }
  @media(max-width: 1200px) {
    margin: 0;
  }
  @media(max-width: 576px) {
    justify-content: start;
  }
`;
const TopCol = styled(StyledCol)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px){
    flex-direction: column;
    & button {
      margin-top: 20px;
    }
  }
`;
const StyledRow = styled(Row)`
  margin: 50px 0;
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  @media(max-width: 1200px) {
    padding: 10px;
    padding-top: 100px;
  }
  text-align: left;
  flex-direction: column;
  background: var(--primary-color-dark);
  background-image: radial-gradient(circle at 50% 150%, var(--faded-gradient-color), transparent 70%);
`;
export default Customize;