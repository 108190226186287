import styled from "styled-components";
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import inclusive1 from "assets/images/inclusive-1.jpg";
import inclusive2 from "assets/images/inclusive-2.jpg";
import inclusive3 from "assets/images/inclusive-3.jpg";

const Inclusive = () => {

  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer>
      <StyledContentContainer>
        <StyledRow>
          <StyledCol span={24}>
            <HTwo><Trans i18nKey="inclusive.header" components={[<span />]} /></HTwo>
            <StyledParagraph>{t("inclusive.subHeader")}</StyledParagraph>
          </StyledCol>
        </StyledRow>
        <StyledRow gutter={32}>
          <ImgCol md={8} sm={24}>
            <StyledImg src={inclusive1} alt="People performing row exercise" />
          </ImgCol>
          <ImgCol md={8} sm={24}>
            <StyledImg src={inclusive2} alt="Person performing crunch exercise" />
          </ImgCol>
          <ImgCol md={8} sm={24}>
            <StyledImg src={inclusive3} alt="Person performing lunge exercise" />
          </ImgCol>
        </StyledRow>
      </StyledContentContainer>
    </StyledSectionContainer>
  )
}
const StyledContentContainer = styled(ContentContainer)`
  width: 100%;
`;
const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;
const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: right;
  @media(max-width: 576px) {
    padding: 20px 10px;
    text-align: right;
  }
  `;
const StyledRow = styled(Row)`
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  text-align: left;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 0;
  }
`;
const StyledImg = styled.img`
  position: relative;
  object-fit: cover;
  `;
const ImgCol = styled(StyledCol)`
  margin-top: 60px;
  position: relative;
  width: 100%;
`;
export default Inclusive;
