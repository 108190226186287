import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import { Link } from "components";
import useMediaQuery from "hooks/useMediaQuery";
import locales from "localisation";

const LanguageDropdown = () => {

  const isMobile = useMediaQuery('(max-width: 900px)');
  const { t, i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState()

  const setLanguage = (key) => {
    setSelectedLanguage(key)
    setTimeout(() => i18n.changeLanguage(key), 0)
    localStorage.setItem('lang', key)
  }

  useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      setLanguage(savedLang)
    } else {
      setLanguage('en')
    }
  }, [])

  return (
    <>
      <Dropdown
        dropdownRender={() => (
          <StyledMenu 
            onClick={({ key }) => setLanguage(key)} 
            selectedKeys={[selectedLanguage]} 
            items={_.keys(locales).map((key) => ({ key, label: (<Link>{_.upperCase(key)}</Link>) }))} 
          />
        )}
        placement={!isMobile ? 'bottomRight' : 'bottomLeft'}
      >
        <Link onClick={(e) => e.preventDefault()}>
          {isMobile && t("globals.language") + " : "}{_.upperCase(selectedLanguage)}
        </Link>
      </Dropdown>
    </>
  )
}

const StyledMenu = styled(Menu)`
  min-width: 200px;
  border: 1px solid var(--border-color);
  &.ant-dropdown-menu {
    z-index: 9999;
    position: relative;
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px); 
    background: black;
  }
  &.ant-dropdown-menu .ant-dropdown-menu-item {
    &:hover {
      background: var(--primary-color-darker);
    }
  }
  &.ant-dropdown-menu .ant-dropdown-menu-item-selected {
    background: var(--primary-color);
    &:hover {
      background: var(--primary-color);
    }
    & .ant-dropdown-menu-title-content {
      color: var(--text-color);
      font-weight: 600;
    }
  }
  & .ant-dropdown-menu-title-content {
    color: var(--text-color);
  }
`;

export default LanguageDropdown;