import styled from "styled-components";
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { Button, SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import fitnessFamily from "assets/images/fitness-family.jpg";

const FitnessFamily = ({ showForm }) => {

  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer>
      <ContentContainer>
        <StyledRow>
          <StyledCol span={24}>
            <HTwo><Trans i18nKey="fitness.header" components={[<span />, <p />]} /></HTwo>
            <StyledParagraph>{t("fitness.subHeader")}</StyledParagraph>
            <div style={{ marginTop: 30 }}>
              <Button type="primary" size="large" onClick={showForm}>{t("fitness.cta")}</Button>
            </div>
          </StyledCol>
        <StyledRow>
        </StyledRow>
          <ImgCol span={24}>
            <StyledImg src={fitnessFamily} alt="Exercise gym" />
          </ImgCol>
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;
const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 10px;
    text-align: right;
  }
`;
const StyledRow = styled(Row)`
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  text-align: center;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 0;
  }
`;
const StyledImg = styled.img`
  position: relative;
  object-fit: cover;
  margin-bottom: 40px;
  max-height: 50vh;
  @media (max-width: 1200px) {
    min-height: 250px;
  }
`;
const ImgCol = styled(StyledCol)`
  margin-top: 60px;
  position: relative;
  
`;
export default FitnessFamily;
