import styled from 'styled-components';
import { Button } from 'antd';

export default styled(Button)`
  &&& {
    font-weight: 500;
    border-radius: 2px;
    &.ant-btn-lg {
      padding: 0px 24px;
      height: 54px;
      min-width: 156px;
    }
    &.ant-btn-default {
      color: var(--off-white);
      background: transparent;
      &:hover {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
    &.ant-btn-primary {
      color: var(--off-white);
      background: var(--primary-color);
      &:hover {
        background: red;
      }
    }
    &:hover {
      box-shadow: 0px 0px 10px var(--faded-gradient-color);
    }
  }
`;