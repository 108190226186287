import { Modal } from "antd";
import styled from "styled-components";

const test = (
  <>
    <div lf-root='{"accountKey": "PainandGain", "myLogiFitUrl": "https://prd.mylogifit.com/LogifitWeb", "myLogiFitServiceUrl": "https://prd.mylogifit.com/LogifitWebService" }'></div>
    <div lf-widget-id="63849B2F-2B27-4055-83FE-D33D4FBF8703" />
  </>
);
export default function ContactForm({ isVisible, setIsVisible }) {

  return (
    <>
      <Modal 
        open={isVisible} 
        onCancel={() => setIsVisible(false)} 
        footer={null} 
        width={Math.min(window.innerWidth, 760)}
        forceRender={true}
      >
        <StyledForm>
          {test}
        </StyledForm>
      </Modal>
    </>
  )
}

const StyledForm = styled.div`
  line-height: 1;
  min-height: 200px;
`;