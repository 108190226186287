import _ from "lodash";
import styled from "styled-components";
import { Layout } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, LanguageDropdown } from "components";
import useMediaQuery from "hooks/useMediaQuery";
import useScroll from "hooks/useScroll";
import logo from "assets/images/logo-full.png";
import menuIcon from "assets/images/menu-icon.png";
import closeIcon from "assets/images/close-menu-icon.png";
const { Header } = Layout;

const sections = [
  "home",
  "membership",
  "aboutUs",
  "testimonials",
]

const AppHeader = ({ showForm }) => {

  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [isExpanded, setIsExpanded] = useState(false);
  const scrollPos = useScroll()
  const { t } = useTranslation()

  if (isMobile) {
    return (
      <>
        <StyledHeader $isScrolled={scrollPos > 0}>
          <StyledImg src={logo} alt="Pain and Gain" />
          <MenuContainer>
            <MenuImg 
              src={menuIcon}
              alt="Menu button" 
              onClick={() => setIsExpanded(true)} 
              $active={!isExpanded}
            />
            <MenuImg 
              src={closeIcon}
              alt="Menu button" 
              onClick={() => setIsExpanded(false)} 
              $active={isExpanded}
            />
          </MenuContainer>
          <Expandable $active={isExpanded}>
            {sections.map((key) => (
              <Link 
                key={key} 
                onClick={() => setIsExpanded(false)}
                href={`#${key}`}
              >{ _.startCase(t("globals." + key)) }</Link>
            ))}  
            <Link onClick={() => { setIsExpanded(false); showForm(); }}>{t("globals.contact")}</Link>
          </Expandable>
        </StyledHeader>
      </>
    );
  } else {
    return (
      <StyledHeader $isScrolled={scrollPos > 0}>
        <Links>
          {sections.map((key) => <Link key={key} href={`#${key}`}>{ _.startCase(t("globals." + key)) }</Link>)}      
        </Links>
        <StyledImg src={logo} alt="Pain and Gain" />
        <div>
          <LanguageDropdown />
          <a href="https://xnfzpshodxz.typeform.com/to/J5G2IwXq" target="_blank">
            <ContactButton 
              type="primary" 
              size="large"
            >{t("globals.contact")}</ContactButton>
          </a>
        </div>
      </StyledHeader>
    )
  }
}

const MenuContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 9999;
`;
const Expandable = styled.div`
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px); 
  background: black;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0;
  padding-top: 90px;
  z-index: 9998;
  transform: ${props => (
   props.$active ? 'scaleY(1)' : 'scaleY(0)'
  )};
  transition: transform 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  & ${Link} {
    background: black;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    font-weight: 500;
    color: var(--text-color);
    & span {
      text-decoration: underline;
    }
  }
`;
const ContactButton = styled(Button)`
  &&& {
    border-radius: 30px;
    margin-left: 20px;
    max-height: 40px;
  }
`;
const Links = styled.div`
  & > ${Link} {
    margin: 0 16px;
    font-size: 16px;
  }
`;
const StyledImg = styled.img`
  height: 40px;
  position: absolute;
  left: calc(50% - 100px);
  z-index: 9999;
  @media(max-width: 1200px) {
    height: 25px;
    left: calc(100% - 160px);
  }
`;
const MenuImg = styled(StyledImg)`
  height: 20px;
  opacity: ${props => props.$active ? 1 : 0};
  transform: ${props => props.$active ? 'translate(0px, 0)' : 'translate(0px, -50px)'};
  transition: all 300ms;
  position: absolute;
  top: -0px;
  right: -16px;
  left: unset;
`;
const StyledHeader = styled(Header)`
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px); 
  background: transparent;
  backdrop-filter: ${props => props.$isScrolled && 'blur(10px) brightness(0.5)'};
  -webkit-backdrop-filter: ${props => props.$isScrolled && 'blur(10px) brightness(0.5)'};
  transition: backdrop-filter 100ms;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 998;
  height: 90px;
  margin-bottom: -90px;
  &.ant-layout-header {
    padding: 0 40px;
  }
  overflow: visible;
`;
export default AppHeader;