import styled from "styled-components";
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, ContentContainer, HTwo, Paragraph } from "components";
import aboutUs from "assets/images/about-us.jpg";

const AboutUs = () => {

  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer id="aboutUs">
      <ContentContainer>
        <StyledRow>
          <StyledCol span={24}>
            <HTwo><Trans i18nKey="aboutUs.header" components={[<span />]} /></HTwo>
            <StyledParagraph>{t("aboutUs.subHeader")}</StyledParagraph>
          </StyledCol>
        <StyledRow>
        </StyledRow>
          <ImgCol span={24}>
            <StyledImg src={aboutUs} alt="About us" />
          </ImgCol>
        </StyledRow>
      </ContentContainer>
    </StyledSectionContainer>
  )
}

const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;
const StyledCol = styled(Col)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 10px;
    text-align: right;
  }
`;
const StyledRow = styled(Row)`
  
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 0;
  padding-bottom: 0px;
  text-align: left;
  flex-direction: column;
  @media(max-width: 576px) {
    padding: 20px 0;
  }
`;
const StyledImg = styled.img`
  position: relative;
  object-fit: cover;
  max-height: 50vh;
  @media (max-width: 1200px) {
    min-height: 250px;
  }
`;
const ImgCol = styled(StyledCol)`
  margin-top: 60px;
  position: relative;
  
`;
export default AboutUs;
