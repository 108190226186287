import styled from "styled-components";
import { Layout } from "antd";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Paragraph, Link } from "components";
import twitter from "assets/images/twitter.png"
import discord from "assets/images/discord.png"
import logo from "assets/images/logo-white.png"

const { Footer } = Layout;

const sections = [
  "home",
  "membership",
  "aboutUs",
  "testimonials",
  { label: "contact", href: "https://xnfzpshodxz.typeform.com/to/J5G2IwXq" }
]

const AppFooter = () => {

  const { t } = useTranslation()

  return (
    <StyledFooter>
      <LogoImg src={logo} alt="Pain and Gain" />
      <Links> 
        {sections.map((section) => section.href ? (
            <StyledLink key={section.href} href={section.href} target="_blank">{_.startCase(t("globals." + section.label))}</StyledLink>
          ) : (
            <StyledLink key={section} href={`#${section}`}>{_.startCase(t("globals." + section))}</StyledLink>
          )
        )}
      </Links>
      <StyledParagraph>
      {t("footer.text")}
      </StyledParagraph>
      <div>
        <a href="" >
          <SocialImg src={twitter} alt="Twitter icon" />
        </a>
        <a href="" >
          <SocialImg src={discord} alt="Discord icon" />
        </a>
      </div>
      <Paragraph>2023 Pain and Gain - {t("globals.rightsReserved")}</Paragraph>
      <Paragraph>+31174500340</Paragraph>
      <Paragraph>info@painandgain.nl</Paragraph>
      <Paragraph style={{ lineHeight: 1.3 }}>
        Leemolen 48, 2678 MH De Lier
      </Paragraph>
      <ToSBox>
        <a href="https://docs.google.com/document/d/1GKqfT2tUQPWQyNG7_9j-MOkQOrp-AC3XbqaxJUJG7sE/edit?usp=sharing" target="_blank">{t("globals.privacyPolicy")}</a>
        {/* <a href="">{t("globals.contactUs")}</a> */}
      </ToSBox>
    </StyledFooter>
  )
}

const StyledParagraph = styled(Paragraph)`
  padding: 0 20px;
  max-width: 820px;
`;
const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media(max-width: 576px) {
    flex-direction: column;
    margin-top: 20px;
  }
  `;
const StyledLink = styled(Link)`
  padding: 30px 20px;
  @media(max-width: 576px) {
    padding: 16px 0;
  }
  color: white;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    color: black;
  }
`;
const LogoImg = styled.img`
  max-width: 140px;
`;
const SocialImg = styled.img`
  margin: 10px 10px;
  opacity: 0.9;
  &:hover {
    opacity: 1;
  }
`;
const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle at 50% 100%, rgba(255, 255, 255, 0.1), transparent 100%);
  background-color: var(--primary-color);
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  @media(max-width: 576px) {
    padding: 60px 30px;
    & ${StyledParagraph} {
      display: none;
    }
  }
`;
const ToSBox = styled.div`
  & a {
    text-decoration: underline;
    color: var(--text-color);
    margin: 10px;
    position: relative;
    top: 10px;
  }
`;

export default AppFooter;