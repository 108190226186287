import { useState } from 'react';
import styled from "styled-components"; 
import { Radio, Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SlimContentContainer, Button, SectionContainer, HTwo, Paragraph } from "components";

const items = [{
  key: "focused",
  price3mo: 40,
  price12mo: 30,
  price24mo: 25,
}, {
  key: "daytime",
  price3mo: 50,
  price12mo: 40,
  // price24mo: ,
}, {
  key: "academic",
  // price3mo: ,
  price12mo: 37.50,
  // price24mo: ,
}, {
  active: true,
  key: "total",
  price1mo: 75,
  price3mo: 65,
  price12mo: 50,
  price24mo: 45,
}]  

const Simple = ({ showForm }) => {

  const [active, setActive] = useState('price12mo');
  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer id="membership">
      <SlimContentContainer>
        <HTwo><Trans i18nKey="pricing.header" components={[<span />]} /></HTwo>
        <StyledParagraph>{t("pricing.subHeader")}</StyledParagraph>
        <RadioGroup onChange={(e) => setActive(e.target.value)} defaultValue="price12mo">
          <Radio.Button value="price1mo">{t("pricing.1mo")}</Radio.Button>
          <Radio.Button value="price3mo">{t("pricing.3mo")}</Radio.Button>
          <Radio.Button value="price12mo">{t("pricing.12mo")}<span>Save 20%</span></Radio.Button>
          <Radio.Button value="price24mo">{t("pricing.24mo")}</Radio.Button>
        </RadioGroup>
        <StyledRow gutter={20}>
          {items
            .filter((item) => item[active])
            .map((item) => (
            <Col sm={24} md={12} key={item.key}>
              <Panel $active={item.active} $mostPopular={t(`pricing.mostPopular`)}>
                <div>
                  <div>
                    <Strong>{t(`pricing.${item.key}.header`)}</Strong>
                    <Sub>{t(`pricing.${item.key}.subHeader`)}</Sub>
                  </div>
                  <Price>{item[active] ? `€${item[active]}` : "~"}</Price>
                </div>
                <Button size="large" onClick={showForm}>{t(`pricing.${item.key}.btnText`)}</Button>
                <StyledParagraph>{t(`pricing.${item.key}.desc`)}</StyledParagraph>
              </Panel>
            </Col>
          ))} 
          {/* <ExtraContainer>
            <StyledParagraph>{t(`pricing.registration`)}: €25</StyledParagraph>
            <StyledParagraph>{t(`pricing.tagPrice`)}: €20</StyledParagraph>
          </ExtraContainer> */}
        </StyledRow>
      </SlimContentContainer>
    </StyledSectionContainer>
  )
}
const ExtraContainer = styled.div`
  padding: 30px;
  text-align: center;
  width: 100%;
  &&& > * {
    margin: 10px 0;
    display: block;
  }
`;
const RadioGroup = styled(Radio.Group)`
  position: relative;
  margin-top: 30px;
  .ant-radio-button-wrapper {
    background: var(--black);
    color: rgba(255, 255, 255, 0.6);
    padding: 5px 10px;
    @media (max-width: 442px) {
      padding: 5px 5px;
    }
    height: 45px;
    border-color: rgba(27, 27, 27, 1);
    font-size: 16px;
    & > span {
      padding: 0 10px;
    }
    & > span:last-child > span {
      background: rgba(56, 56, 56, 1);
      color: var(--white);
      padding: 3px 6px;
      border-radius: 2px;
      font-size: 12px;
      margin-left: 5px;
      @media (max-width: 442px) {
        display: none;
      }
    }
    &:first-child, &:last-child {
      border-color: rgba(27, 27, 27, 1);
    }
    &:before {
      display: none;
    }
  }
  .ant-radio-button-wrapper-checked {
    background: var(--primary-color);
    border-radius: 3px;
    color: white;
    &:hover {
      color: white;
      border-color: transparent;
    }
    & > span:last-child > span {
      opacity: 1;
      background: white;
      color: var(--primary-color);
    }
  }
`;
const Price = styled(Paragraph)`
  font-weight: 600;
  font-size: 30px;
  margin: 0;
`;
const Sub = styled(Paragraph)`
  opacity: 0.9;
  font-size: 16px;
  margin: 0;
  line-height: 1;
`;
const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;
const Strong = styled(StyledParagraph)`
  font-weight: 500;
  color: rgba(251, 251, 251, 1);
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin: 0;
`;
const StyledRow = styled(Row)`
  margin-top: 60px;
  position: relative;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    left: -0%; top: -0%; width: 100%; height: 100%;
    background: radial-gradient(circle at 50% 50%, var(--faded-gradient-color), transparent 80%);
    z-index: 1;
  }
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 30px;
  padding-bottom: 0;
  background: var(--primary-color-dark);
  text-align: center;
  @media (max-width: 767px) and (min-width: 576px) {
    padding: 0px 30px;
  }
  & ${Paragraph} {
    margin: 0;
  }
`;
const Panel = styled.div`
  border: 1px solid;
  margin-bottom: 20px;
  border-radius: 6px;
  text-align: left;
  background: ${props => props.$active ? 'rgba(57, 18, 18, 1)' : 'var(--primary-color-dark)'};
  border-color: ${props => props.$active ? 'var(--primary-color)' : 'rgba(253, 253, 253, 0.1)'};
  z-index: 2;
  position: relative;
  min-height: 300px;

  &:before {
    content: ${props => props.$active ? "\"" + props.$mostPopular + " 🩸\"" : ""};
    background: white;
    color: var(--primary-color);
    padding: 4px 8px;
    font-family: "Work Sans";
    font-weight: 400;
    border-radius: 3px;
    position: absolute;
    font-size: 12px;
    top: -12px;
    right: 20px;
  }

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  & > ${Paragraph} {
    padding: 20px;
    padding-top: 0;
    opacity: 0.7;
    font-size: 14px;
  }

  &&& > ${Button} {
    margin: 10px 20px;
    margin-bottom: 30px;
    width: calc(100% - 40px);
    box-sizing: border-box;
    border: none;
    background-color: ${props => props.$active ? 'var(--primary-color)' : 'rgba(56, 56, 56, 1)'};
    &:hover {
      color: ${props => props.$active ? 'white' : 'var(--primary-color)'};
    }
  }
`;
export default Simple;