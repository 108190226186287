import styled from "styled-components"; 
import { useEffect, useState } from 'react';
import Vimeo from "@vimeo/player";
import { useTranslation, Trans } from "react-i18next";
import { Button, SlimContentContainer, SectionContainer, HOne, HTwo, Paragraph } from "components";
import videoSplash from "assets/images/video-splash.png";

const Splash = ({ showForm }) => {

  const { t } = useTranslation()
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    const player = new Vimeo(iframe);

    player.on('play', function() {
      setVideoLoaded(true);
    });
  }, [])

  return (
    <SplashWrapper $bgVisible={!videoLoaded}>
      <StyledSectionContainer id="home" $videoLoaded={videoLoaded}>
        <StyledSlimContentContainer>
          <HOne><Trans i18nKey="splash.header" components={[<span />]} /></HOne>
          <Paragraph>{t("splash.subHeader")}</Paragraph>
          <Button onClick={showForm} size="large" type="primary">{t("splash.joinUsBtn")}</Button>
          <Button 
            size="large"
            onClick={() => document.getElementsByTagName('footer')[0].scrollIntoView()}
          >{t("splash.learnMoreBtn")}</Button>
        </StyledSlimContentContainer>
      </StyledSectionContainer>
      <VideoWrapper $visible={videoLoaded}>
        <iframe 
          src="https://player.vimeo.com/video/902160154?title=0&byline=0&portrait=0&sidedock=0&autoplay=1&muted=1&controls=false&loop=1" 
          width="100%" 
          height="100%" 
          frameBorder="0" 
          scrolling="no" 
          allow="autoplay; encrypted-media" 
          allowFullScreen=""
        ></iframe>
      </VideoWrapper>
    </SplashWrapper>
  )
}

const VideoWrapper = styled.div`
  display: ${(props) => props.$visible || 'none'};
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  left: 0;
  @media (max-width: 1200px) {
    width: 200%;
    height: 100%;
    left: -50%;
  }
  @media (max-width: 576px) {
    width: 400%;
    height: 100%;
    left: -150%;
  }
`;
const SplashWrapper = styled.div`
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px); 
  background-image: ${(props) => props.$bgVisible && "url(" + videoSplash + ")"};
  background-size: cover;
`;
const StyledSlimContentContainer = styled(SlimContentContainer)`
  position: relative;
  z-index: 1;
`;
const StyledSectionContainer = styled(SectionContainer)`
  background: #00000033;
  backdrop-filter: ${props => props.$videoLoaded || 'blur(10px)'};
  position: relative;
  z-index: 1;
  min-height: 100vh;
  padding-bottom: 0;
  text-align: center;
  & ${HOne} {
    padding: 0 40px;
  }
  & button {
    margin: 10px;
  }
  & ${HOne}, ${HTwo} {
    margin-top: 90px;
  }
  & ${Paragraph} {
    margin-bottom: 40px;
    padding: 0 10px;
  }
  @media (max-width: 576px) {
    padding: 20px;
  }
`;

export default Splash;
