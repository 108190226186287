import styled from "styled-components"; 
import { Row, Col } from 'antd';
import { useTranslation, Trans } from "react-i18next";
import { SlimContentContainer, Button, SectionContainer, HTwo, Paragraph } from "components"; 
import check from "assets/images/check-outlined.png";
import specialPackage from "assets/images/personal-training.jpeg";

const items = [99];

const SpecialPackages = ({ showForm }) => {

  const { t } = useTranslation()
  
  return (
    <StyledSectionContainer id="specialPackages">
      <SlimContentContainer>
        <HTwo><Trans i18nKey="specialPackages.header" components={[<span />]} /></HTwo>
        <StyledParagraph>{t("specialPackages.subHeader")}</StyledParagraph>
        <StyledRow justify="center">
          {items
            .map((item, i) => (
              <Col md={18} sm={24} lg={16} key={`package-${i}`}>
                <Panel $active={i === 1}>
                  <div>
                    <div>
                      <StyledImg src={specialPackage} />
                      <Strong>{t(`specialPackages.${i}.header`)}</Strong>
                      <Sub>
                        <Trans 
                          i18nKey={`specialPackages.${i}.subHeader`}
                          components={[<u/>]}
                        />
                      </Sub>
                    </div>
                    {/* <Price>{item}€</Price> */}
                  </div>
                  <StyledParagraph>
                    <Trans 
                      i18nKey={`specialPackages.${i}.description`} 
                      components={[<u/>]}
                    />
                  </StyledParagraph>
                  <Button size="large" onClick={showForm}>{t(`specialPackages.${i}.btnText`)}</Button>
                  <Divider />
                  <ol>
                    {new Array(3)
                      .fill(true)
                      .map((_, j) => (
                        <li key={`specialPackages.${i}.items.${j}`}>
                          <Strong>
                            <Trans i18nKey={`specialPackages.${i}.items.${j}.header`} />
                          </Strong>
                          <Paragraph>
                            <Trans i18nKey={`specialPackages.${i}.items.${j}.text`} />
                          </Paragraph>
                        </li>
                      )
                    )}
                  </ol>
                </Panel>
              </Col>
            )
          )}
        </StyledRow>
      </SlimContentContainer>
    </StyledSectionContainer>
  )
}
const StyledImg = styled.img`
  height: 200px;
  width: 100%;
  background: red;
  margin-bottom: 20px;
  border-radius: 3px;
  object-fit: cover;
  object-position: bottom;
`;
const Divider = styled.div`
  margin: 0 20px;
  width: calc(100% - 40px);
  height: 2px;
  border-bottom: 1px solid rgba(253, 253, 253, 0.1);
`;
const Sub = styled(Paragraph)`
  opacity: 0.9;
  font-size: 16px;
  margin: 0;
  line-height: 1;
`;
const StyledParagraph = styled(Paragraph)`
  margin: 0;
`;
const Strong = styled(StyledParagraph)`
  font-weight: 500;
  color: rgba(251, 251, 251, 1);
  font-size: 24px;
  font-weight: 600;
  display: block;
  margin: 0;
  &&& {
    margin-bottom: 5px;
  }
`;
const StyledRow = styled(Row)`
  margin-top: 60px;
  position: relative;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    left: -0%; top: -0%; width: 100%; height: 100%;
    background: radial-gradient(circle at 50% 50%, var(--faded-gradient-color), transparent 70%);
    z-index: 1;
  }
`;
const StyledSectionContainer = styled(SectionContainer)`
  padding: 100px 30px;
  padding-bottom: 0;
  background: var(--primary-color-dark);
  text-align: center;
  @media (max-width: 767px) and (min-width: 576px) {
    padding: 0px 30px;
  }
  & ${Paragraph} {
    margin: 0;
  }
`;
const Panel = styled.div`
  border: 1px solid;
  margin-bottom: 20px;
  border-radius: 6px;
  text-align: left;
  background: ${props => props.$active ? 'rgba(57, 18, 18, 1)' : 'var(--primary-color-dark)'};
  border-color: ${props => props.$active ? 'var(--primary-color)' : 'rgba(253, 253, 253, 0.1)'};
  z-index: 2;
  position: relative;
  min-height: 300px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px; 
    & > div {
      width: 100%;
    } 
  }

  & > ${Paragraph} {
    padding: 20px;
    padding-top: 0;
    opacity: 0.7;
    font-size: 14px;
  }

  &&& > ${Button} {
    margin: 10px 20px;
    margin-bottom: 30px;
    width: calc(100% - 40px);
    box-sizing: border-box;
    border: none;
    background-color: ${props => props.$active ? 'var(--primary-color)' : 'rgba(56, 56, 56, 1)'};
    &:hover {
      color: ${props => props.$active ? 'white' : 'var(--primary-color)'};
    }
  }

  & ol {
    padding: 20px;
    padding-bottom: 0;
    & ${Strong}, & ${Paragraph} {
      opacity: 0.9;
    }
    & ${Strong} {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 1;
    }
    & li {
      margin-bottom: 20px;
      margin-left: 40px;
      position: relative;
      &:before {
        content: ""; 
        background: url(${check});
        text-shadow: 0px 0px 5px var(--primary-color-dark);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        float: left;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        margin-left: -40px;
        width: 16px;
        height: 16px;
      }
    }
  }
`;
export default SpecialPackages;