import styled from "styled-components";
import ContentContainer from "components/ContentContainer";

export default styled(ContentContainer)`
  margin: auto;
  max-width: 900px;
  @media (max-width: 576px) {
    max-width: 540px;
    /* padding: 0 30px; */
  }
  @media (max-width: 768px) {
    max-width: 720px;
  }
`;